import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const RadialChartComponent = ({ statistics }) => {
  const totalStats = {
    smsSuccess: statistics.daily_stats.reduce((sum, stat) => sum + stat.sms_success, 0),
    registrationSuccess: statistics.daily_stats.reduce((sum, stat) => sum + stat.registration_success, 0),
    smsErrors: statistics.daily_stats.reduce((sum, stat) => sum + stat.sms_errors, 0),
    registrationErrors: statistics.daily_stats.reduce((sum, stat) => sum + stat.registration_errors, 0)
  };

  const data = {
    labels: ['SMS Success', 'Registration Success', 'SMS Errors', 'Registration Errors'],
    datasets: [
      {
        data: [
          totalStats.smsSuccess,
          totalStats.registrationSuccess,
          totalStats.smsErrors,
          totalStats.registrationErrors
        ],
        backgroundColor: [
          '#10B981',
          '#3B82F6',
          '#EF4444',
          '#F59E0B'
        ],
        borderColor: [
          '#059669',
          '#2563EB',
          '#DC2626',
          '#D97706'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return chart.data.labels.map((label, i) => ({
              text: `${label}: ${datasets[0].data[i].toLocaleString()}`,
              fillStyle: datasets[0].backgroundColor[i],
              strokeStyle: datasets[0].borderColor[i],
              lineWidth: 1,
              hidden: false,
              index: i
            }));
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.label}: ${context.raw.toLocaleString()}`;
          }
        }
      }
    },
    cutout: '50%',
    maintainAspectRatio: false // Добавляем это для лучшего контроля высоты
  };

  return (
    <div className="w-full bg-white shadow-md rounded-lg p-4 flex flex-col items-center">
      <h3 className="text-lg font-medium text-gray-700 mb-10 text-center">Statistics Overview</h3>
      <div className="h-96 w-full flex justify-center items-center">
        <div className="w-4/5 h-full"> {/* Контейнер для графика */}
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default RadialChartComponent;