import React, { useState } from "react";

const Whitelist = ({ domains }) => {
  const [updatedDomains, setUpdatedDomains] = useState(domains);
  const [activeTab, setActiveTab] = useState(domains[0]?.id || null); // Управление активным табом
  const [searchQuery, setSearchQuery] = useState("");

  // Получение CSRF-токена
  const getCSRFToken = () => {
    return document.querySelector('meta[name="csrf-token"]').getAttribute("content");
  };

  // Обработчик изменения чекбокса
  const handleCheckboxChange = (domainId, whitelistId) => {
    setUpdatedDomains((prevDomains) =>
      prevDomains.map((domain) => {
        if (domain.id !== domainId) return domain;

        const updatedWhitelists = domain.whitelists.map((whitelist) =>
          whitelist.id === whitelistId
            ? { ...whitelist, show: !whitelist.show }
            : whitelist
        );

        return { ...domain, whitelists: updatedWhitelists };
      })
    );
  };

  // Обработчик отправки формы
  const handleSubmit = async (event, domainId) => {
    event.preventDefault();
  
    const domain = updatedDomains.find((d) => d.id === domainId);
  
    // Формируем данные для отправки
    const whitelistsToSubmit = domain.whitelists.reduce((acc, whitelist) => {
      if (whitelist.show) {
        acc[whitelist.id] = whitelist.show;
      }
      return acc;
    }, {});
  
    try {
      const response = await fetch(`/whitelists`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify({ domain_id: domainId, whitelists: whitelistsToSubmit }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Whitelist updated successfully:", data);
  
        // Обновляем локальное состояние
        setUpdatedDomains((prevDomains) =>
          prevDomains.map((domain) =>
            domain.id === domainId
              ? {
                  ...domain,
                  whitelists: domain.whitelists.map((whitelist) => ({
                    ...whitelist,
                    show: !!whitelistsToSubmit[whitelist.id],
                  })),
                }
              : domain
          )
        );
  
        alert("Whitelist updated successfully!");
      } else {
        console.error("Failed to update whitelist:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating whitelist:", error);
    }
  };
  

  return (
    <div className="w-full p-6 bg-gray-100">
      <h3 className="text-2xl font-bold mb-4 text-center py-4 border-b">
        Whitelist
      </h3>
  
      <div className="tabs flex border-b overflow-x-auto">
        {updatedDomains.map((domain) => (
          <button
            key={domain.id}
            onClick={() => setActiveTab(domain.id)}
            className={`p-3 cursor-pointer ${
              activeTab === domain.id
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-500 hover:text-blue-500"
            }`}
          >
            {domain.name}
          </button>
        ))}
      </div>
  
      {updatedDomains.map((domain) => (
        <div
          key={domain.id}
          className={`${
            activeTab === domain.id ? "block" : "hidden"
          } p-6 w-full`}
        >
          <form
            onSubmit={(event) => handleSubmit(event, domain.id)}
            className="bg-white w-full rounded shadow-md p-6 flex flex-col items-center"
          >
            
            {/* Кнопка сохранения */}
            <div className="w-full mb-4 flex justify-end">
              <button
                type="submit"
                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition-colors"
                >
                Save whitelist
              </button>
            </div>

            {/* Поле поиска */}
            <div className="w-full mb-4">
              <input
                type="text"
                placeholder="Search landing..."
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {/* Центрируем таблицу */}
            <div className="mx-auto w-full bg-white rounded-md shadow-lg">
              <table className="w-full border-collapse border border-gray-300 text-sm">
                <thead>
                  <tr className="bg-gray-200 text-left">
                    <th className="p-3 border border-gray-300">Domain</th>
                    <th className="p-3 border border-gray-300">Landing</th>
                    <th className="p-3 border border-gray-300 text-center">
                      Show
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {domain.whitelists
                    .filter((whitelist) =>
                      whitelist.landing?.name
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    ) // Фильтрация по поисковому запросу
                    .sort((a, b) => b.show - a.show) // Отмеченные первыми
                    .map((whitelist, index) => (
                      <tr
                        key={whitelist.id}
                        className={`hover:bg-gray-100 ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td className="p-3 border border-gray-300">
                          {domain.name}
                        </td>
                        <td className="p-3 border border-gray-300">
                          {whitelist.landing?.name || "No Landing"}
                        </td>
                        <td className="p-3 border border-gray-300 text-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4"
                            checked={whitelist.show}
                            onChange={() =>
                              handleCheckboxChange(domain.id, whitelist.id)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      ))}
    </div>
  );
  
};

export default Whitelist;
