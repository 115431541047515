import React, { useState, useEffect, useRef } from 'react';
import RadialChartComponent from './RadialChartComponent';
import DomainSelect from './DomainSelect';
import LandingSelect from './LandingSelect';
import CountrySelect from './CountrySelect';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import styled from 'styled-components';

const customLocale = {
  ...enUS,
  options: {
    ...enUS.options,
    weekStartsOn: 1
  }
};

const DateRangePickerWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DateRangeInput = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  height: 37px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  cursor: pointer;
`;

const DateRangePickerPopup = styled.div`
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const SubmitButton = styled.button`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Preloader = styled.div`
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const StatisticsApp = ({ domains: initialDomainsData, landings: initialLandingData }) => {
  const [domains, setDomains] = useState(initialDomainsData);
  const [landings, setLandings] = useState(initialLandingData);
  const [selectedDomain, setSelectedDomain] = useState('all');
  const [selectedLanding, setSelectedLanding] = useState('all');
  const [selectedCountry, setSelectedCountry] = useState({ value: 'All', label: 'All Countries' });
  const [landingOptions, setLandingOptions] = useState([]);
  const [statistics, setStatistics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const dateRangePickerRef = useRef(null);

  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (selectedDomain === 'all') {
      setLandingOptions([{ id: 'all', name: 'All Landings' }]);
    } else {
      const filteredLandings = landings.filter(landing => landing.domain_id === parseInt(selectedDomain));
      setLandingOptions([{ id: 'all', name: 'All Landings' }, ...filteredLandings]);
    }
  }, [selectedDomain, landings]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleGetStatistics = async () => {
    setIsLoading(true);
    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      
      // Устанавливаем время для startDate и endDate
      const adjustedStartDate = new Date(selectedDateRange[0].startDate);
      adjustedStartDate.setHours(0, 0, 0, 0);
  
      const adjustedEndDate = new Date(selectedDateRange[0].endDate);
      adjustedEndDate.setHours(23, 59, 59, 999);
  
      const response = await fetch('/api/statistics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
        body: JSON.stringify({
          selectedDomain,
          selectedLanding,
          selectedCountry: selectedCountry.value,
          dateRange: [{
            startDate: adjustedStartDate.toISOString().split('T')[0] + 'T00:00:00.000Z',
            endDate: adjustedEndDate.toISOString().split('T')[0] + 'T23:59:59.999Z'
          }],
        }),
      });
      const data = await response.json();
      setStatistics(data.statistics);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateRangeFieldClick = () => {
    setIsDateRangePickerOpen(!isDateRangePickerOpen);
  };

  const handleOutsideClick = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      setIsDateRangePickerOpen(false);
    }
  };

  const handleDateRangeChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);
  };

  const handleDomainChange = (event) => {
    const selectedDomainId = event.target.value;
    setSelectedDomain(selectedDomainId);
    setSelectedLanding('all');
  };

  const handleLandingChange = (event) => {
    setSelectedLanding(event.target.value);
  };

  // Конвертация данных в CSV формат
const convertToCSV = (data) => {
  // Секция для данных графика
  const chartData = [
    ['Chart Data'],
    ['Metric', 'Value'],
    ['SMS Success', data.reduce((sum, stat) => sum + stat.sms_success, 0)],
    ['Registration Success', data.reduce((sum, stat) => sum + stat.registration_success, 0)],
    ['SMS Errors', data.reduce((sum, stat) => sum + stat.sms_errors, 0)],
    ['Registration Errors', data.reduce((sum, stat) => sum + stat.registration_errors, 0)],
    [], // Пустая строка для разделения
    ['Detailed Data'], // Заголовок для детальных данных
  ];

  // Заголовки для детальных данных
  const headers = [
    'Date',
    'Page Loads',
    'SMS Success',
    'Registration Success',
    'SMS Errors',
    'Registration Errors',
    'SMS Errors %',
    'Registration Errors %',
    'Success Rate %',
    'Ratio %'
  ];

  const dataToConvert = data.map(stat => ({
    'Date': format(new Date(stat.date), 'dd/MM/yyyy'),
    'Page Loads': stat.page_loads,
    'SMS Success': stat.sms_success,
    'Registration Success': stat.registration_success,
    'SMS Errors': stat.sms_errors,
    'Registration Errors': stat.registration_errors,
    'SMS Errors %': stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100).toFixed(1) + '%' : '0%',
    'Registration Errors %': stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100).toFixed(1) + '%' : '0%',
    'Success Rate %': stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100).toFixed(1) + '%' : '0%',
    'Ratio %': stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100).toFixed(1) + '%' : '0%'
  }));

  // Добавляем строку с тотальными значениями
  const totalRow = {
    'Date': 'Total',
    'Page Loads': data.reduce((sum, stat) => sum + stat.page_loads, 0),
    'SMS Success': data.reduce((sum, stat) => sum + stat.sms_success, 0),
    'Registration Success': data.reduce((sum, stat) => sum + stat.registration_success, 0),
    'SMS Errors': data.reduce((sum, stat) => sum + stat.sms_errors, 0),
    'Registration Errors': data.reduce((sum, stat) => sum + stat.registration_errors, 0),
    'SMS Errors %': (data.reduce((sum, stat) => 
      sum + (stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100) : 0), 0) 
      / data.length).toFixed(1) + '%',
    'Registration Errors %': (data.reduce((sum, stat) => 
      sum + (stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100) : 0), 0)
      / data.length).toFixed(1) + '%',
    'Success Rate %': (data.reduce((sum, stat) => 
      sum + (stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100) : 0), 0)
      / data.length).toFixed(1) + '%',
    'Ratio %': (data.reduce((sum, stat) => 
      sum + (stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100) : 0), 0)
      / data.length).toFixed(1) + '%'
  };

  dataToConvert.push(totalRow);

  // Собираем все части CSV
  let csv = chartData.map(row => row.join(',')).join('\n'); // Данные графика
  csv += '\n' + headers.join(',') + '\n'; // Заголовки детальных данных
  csv += dataToConvert.map(row => 
    headers.map(header => row[header]).join(',')
  ).join('\n');

  return csv;
};

// Функция скачивания CSV
const downloadCSV = () => {
  const csv = convertToCSV(statistics.daily_stats);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', `statistics_${format(selectedDateRange[0].startDate, 'dd-MM-yyyy')}_${format(selectedDateRange[0].endDate, 'dd-MM-yyyy')}.csv`);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

  return (
    <div className="wrapper">
      <div className="block sticky-top">
        <DomainSelect
          domains={domains}
          selectedDomain={selectedDomain}
          onChange={handleDomainChange}
          setDomains={setDomains}
        />

        <LandingSelect
          landings={landingOptions}
          selectedLanding={selectedLanding}
          onChange={handleLandingChange}
          setLandings={setLandings}
        />

        <CountrySelect
          selectedCountry={selectedCountry}
          onChange={setSelectedCountry}
        />

        <DateRangePickerWrapper ref={dateRangePickerRef}>
          <DateRangeInput
            type="text"
            value={`${format(selectedDateRange[0].startDate, 'dd/MM/yyyy')} - ${format(
              selectedDateRange[0].endDate,
              'dd/MM/yyyy'
            )}`}
            readOnly
            onClick={handleDateRangeFieldClick}
            className="datepicker-input"
          />
          {isDateRangePickerOpen && (
            <DateRangePickerPopup>
              <DateRange
                ranges={selectedDateRange}
                onChange={handleDateRangeChange}
                locale={customLocale}
                months={1}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
              />
            </DateRangePickerPopup>
          )}
        </DateRangePickerWrapper>

        <ButtonWrapper>
          <SubmitButton 
            onClick={handleGetStatistics}
            disabled={isLoading}
          >
            {isLoading ? <Preloader /> : 'Get Statistics'}
          </SubmitButton>
        </ButtonWrapper>
      </div>

      {statistics && (
        <div className="block">
          <div className="mx-auto w-full flex flex-col gap-4">
            <div className="grid grid-cols-4 gap-4">

              {/* Информационный блок */}
              <div className="h-fit bg-white shadow-md rounded-lg p-4">
                <div className="grid grid-cols-1 gap-2">
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Domain: </span>
                    <span className="text-blue-600">
                      {selectedDomain === 'all' ? 'All Domains' : domains.find(d => d.id === parseInt(selectedDomain))?.name || 'All Domains'}
                    </span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Landing: </span>
                    <span className="text-blue-600">
                      {selectedLanding === 'all' ? 'All Landings' : landings.find(l => l.id === parseInt(selectedLanding))?.name || 'All Landings'}
                    </span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Country: </span>
                    <span className="text-blue-600">
                      {selectedCountry.label}
                    </span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Period: </span>
                    <span className="text-blue-600">
                      {format(selectedDateRange[0].startDate, 'dd/MM/yyyy')} - {format(selectedDateRange[0].endDate, 'dd/MM/yyyy')}
                    </span>
                  </div>
                  <div className="pt-2 mt-2 border-t border-gray-200">
                    <button
                      onClick={downloadCSV}
                      className="w-full px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Export to CSV
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Радиальный график */}
              <div className="col-span-3">
                <RadialChartComponent statistics={statistics} />
              </div>
            </div>

            

            {/* Таблица */}
            <div className="w-full overflow-hidden bg-white shadow-md rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Page Loads
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      SMS Success
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Registration Success
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      SMS Errors
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Registration Errors
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      SMS Errors %
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Registration Errors %
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Success Rate %
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ratio %
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {statistics.daily_stats.map((stat, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {format(new Date(stat.date), 'dd/MM/yyyy')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {stat.page_loads}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {stat.sms_success}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {stat.registration_success}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {stat.sms_errors}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {stat.registration_errors}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-semibold">
                        {stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100).toFixed(1) : '0'}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-semibold">
                        {stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100).toFixed(1) : '0'}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-semibold">
                        {stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100).toFixed(1) : '0'}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-semibold">
                        {stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100).toFixed(1) : '0'}%
                      </td>
                    </tr>
                  ))}
                  
                  {/* Total row */}
                  <tr className="bg-gray-100 font-semibold">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      Total
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statistics.daily_stats.reduce((sum, stat) => sum + stat.page_loads, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statistics.daily_stats.reduce((sum, stat) => sum + stat.sms_success, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statistics.daily_stats.reduce((sum, stat) => sum + stat.registration_success, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statistics.daily_stats.reduce((sum, stat) => sum + stat.sms_errors, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {statistics.daily_stats.reduce((sum, stat) => sum + stat.registration_errors, 0)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                      {(statistics.daily_stats.reduce((sum, stat) => 
                        sum + (stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100) : 0), 0) 
                        / statistics.daily_stats.length).toFixed(1)}%
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                      {(statistics.daily_stats.reduce((sum, stat) => 
                        sum + (stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100) : 0), 0)
                        / statistics.daily_stats.length).toFixed(1)}%
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                      {(statistics.daily_stats.reduce((sum, stat) => 
                        sum + (stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100) : 0), 0)
                        / statistics.daily_stats.length).toFixed(1)}%
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                      {(statistics.daily_stats.reduce((sum, stat) => 
                        sum + (stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100) : 0), 0)
                        / statistics.daily_stats.length).toFixed(1)}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default StatisticsApp;