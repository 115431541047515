import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ data, errorTypes }) => {
  const chartRef = useRef(null);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    if (!chartRef.current || !data || !errorTypes) {
      return;
    }

    let chartInstance;
    const ctx = chartRef.current.getContext('2d');
    
    try {
      const timestamps = Object.keys(data).sort();
      
      const datasets = errorTypes.map(errorType => {
        const color = getRandomColor();
        return {
          label: errorType,
          data: timestamps.map(timestamp => data[timestamp][errorType] || 0),
          borderColor: color,
          backgroundColor: color,
          tension: 0.1,
          fill: false,
        };
      });

      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: timestamps.map(timestamp => {
            // Форматируем метку времени для оси X
            const [date, time] = timestamp.split(' ');
            const [year, month, day] = date.split('-');
            return `${day}.${month} ${time.slice(0, 5)}`; // Обрезаем секунды
          }),
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              precision: 0,
              stacked: false
            },
            x: {
              stacked: false,
              ticks: {
                maxRotation: 45, // Поворот меток для лучшей читаемости
                minRotation: 45
              }
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 12,
                padding: 15
              }
            },
          },
        },
      });
    } catch (error) {
      console.error('Error creating chart:', error);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data, errorTypes]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md" style={{ height: '400px' }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default LineChart;