import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import MiniBarChart from './MiniBarChart';

const SortableTable = ({ data }) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedMetric, setSelectedMetric] = useState('registrations');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const handleMetricChange = (metric) => {
    setSelectedMetric(metric);
    setDropdownVisible(false);
  };

  const calculateRatio = (item) => {
    if (item.page_load === 0) return "0";
    return ((item[selectedMetric] / item.page_load) * 100).toFixed(2);
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortColumn === 'url') {
      return sortOrder === 'asc' ? a.url.localeCompare(b.url) : b.url.localeCompare(a.url);
    } else if (sortColumn === 'traffic_source') {
      return sortOrder === 'asc' 
        ? (a.traffic_source || '').localeCompare(b.traffic_source || '') 
        : (b.traffic_source || '').localeCompare(a.traffic_source || '');
    } else if (sortColumn === 'ratio') {
      const aRatio = parseFloat(calculateRatio(a));
      const bRatio = parseFloat(calculateRatio(b));
      return sortOrder === 'asc' ? aRatio - bRatio : bRatio - aRatio;
    } else {
      return sortOrder === 'asc' ? a[sortColumn] - b[sortColumn] : b[sortColumn] - a[sortColumn];
    }
  });

  const filteredData = sortedData.filter(item => item.page_load > 0);

  const getSortIndicator = (column) => {
    if (column === sortColumn) {
      return sortOrder === 'asc' ? <span>↑</span> : <span>↓</span>;
    }
    return null;
  };

  const generateChartData = (dailyData) => {
    return Object.keys(dailyData).map(date => {
      const { page_load } = dailyData[date];
      const metricValue = dailyData[date][selectedMetric];
      const ratio = page_load === 0 ? 0 : ((metricValue / page_load) * 100).toFixed(2);
      return {
        label: new Date(date).toLocaleDateString(),
        value: parseFloat(ratio)
      };
    });
  };

  return (
    <table className="statistics-table min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50 dark:bg-slate-800">
        <tr>
          <th onClick={() => handleSort('url')} className="px-6 py-3 text-start cursor-pointer">
            <div className="flex items-center gap-x-2">
              Landing URL {getSortIndicator('url')}
            </div>
          </th>
          <th onClick={() => handleSort('traffic_source')} className="px-6 py-3 text-start cursor-pointer">
            <div className="flex items-center gap-x-2">
              Traffic Source {getSortIndicator('traffic_source')}
            </div>
          </th>
          <th onClick={() => handleSort('page_load')} className="px-6 py-3 text-start cursor-pointer">
            <div className="flex items-center gap-x-2">
              Page Loads {getSortIndicator('page_load')}
            </div>
          </th>
          <th className="px-6 py-3 text-start cursor-pointer relative" ref={dropdownRef}>
            <div className="flex items-center gap-x-2">
              <span onClick={() => handleSort(selectedMetric)} className="cursor-pointer">
                {selectedMetric.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} {getSortIndicator(selectedMetric)}
              </span>
              <FontAwesomeIcon
                icon={faCaretDown}
                className="ml-2 cursor-pointer"
                onClick={() => setDropdownVisible(!dropdownVisible)}
              />
              {dropdownVisible && (
                <div className="absolute top-full left-0 mt-1 w-48 bg-white border rounded shadow-lg z-10">
                  <ul>
                    <li onClick={() => handleMetricChange('registrations')} className="px-4 py-2 cursor-pointer hover:bg-gray-200">Registrations</li>
                    <li onClick={() => handleMetricChange('form_errors')} className="px-4 py-2 cursor-pointer hover:bg-gray-200">Form Errors</li>
                    <li onClick={() => handleMetricChange('sms_successes')} className="px-4 py-2 cursor-pointer hover:bg-gray-200">SMS Successes</li>
                    <li onClick={() => handleMetricChange('sms_errors')} className="px-4 py-2 cursor-pointer hover:bg-gray-200">SMS Errors</li>
                    <li onClick={() => handleMetricChange('app_download')} className="px-4 py-2 cursor-pointer hover:bg-gray-200">App Downloads</li>
                  </ul>
                </div>
              )}
            </div>
          </th>
          <th onClick={() => handleSort('ratio')} className="px-6 py-3 text-start cursor-pointer">
            <div className="flex items-center gap-x-2">
              Ratio {getSortIndicator('ratio')}
            </div>
          </th>
          <th className="px-6 py-3 text-start cursor-pointer">
            {/* Заголовок для графика */}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {filteredData.map((item, index) => (
          <tr key={index} className="table-row hover:bg-gray-50">
            <td className="px-6 py-2 size-px whitespace-nowrap"><div>{item.url}</div></td>
            <td className="px-6 py-2 size-px whitespace-nowrap"><div>{item.traffic_source || '-'}</div></td>
            <td className="px-6 py-2 size-px whitespace-nowrap"><div>{item.page_load}</div></td>
            <td className="px-6 py-2 size-px whitespace-nowrap fixed-width"><div>
              {item[selectedMetric]}
            </div></td>
            <td className="px-6 py-2 size-px whitespace-nowrap"><div>{calculateRatio(item)}%</div></td>
            <td className="px-6 py-2 size-px whitespace-nowrap chart-cell">
              {item.daily_data && Object.keys(item.daily_data).length > 1 ? (
                <MiniBarChart data={generateChartData(item.daily_data)} />
              ) : (
                <div>
                  {/* Пустая ячейка для лендингов без данных */}
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SortableTable;