import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const TrafficSourceSelect = ({ selectedTrafficSource, onChange }) => {
  const [trafficSources, setTrafficSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      marginLeft: '5px'
    }),
  };

  useEffect(() => {
    const fetchTrafficSources = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/traffic_sources');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Проверяем, что data это массив и преобразуем его в правильный формат
        const formattedSources = Array.isArray(data) 
          ? data.map(source => ({
              value: source.value || source.id || source,
              label: source.label || source.name || source
            }))
          : [];
        setTrafficSources(formattedSources);
      } catch (error) {
        console.error('Error fetching traffic sources:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrafficSources();
  }, []);

  const options = [
    { value: 'all', label: 'All Sources' },
    ...(Array.isArray(trafficSources) ? trafficSources : [])
  ];

  return (
    <Select
      value={selectedTrafficSource}
      onChange={onChange}
      options={options}
      isLoading={isLoading}
      isDisabled={isLoading}
      className="select-container"
      classNamePrefix="select"
      placeholder="Select Traffic Source"
      styles={customStyles}
    />
  );
};

export default TrafficSourceSelect;